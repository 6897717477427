<template>
  <div id="app" :lang="$i18n.locale">
    <!-- <div class="layout"> -->
    <navbar v-if="showNavbar" />
    <!-- </div> -->
    <router-view :id="mainContentId" />
    <b-toast id="toast" variant="success" solid></b-toast>
    <theFooter v-if="showFooter" />
    <button 
      class="whatsapp-icon" 
      @click="openWhatsApp">
      <b-icon icon="whatsapp"></b-icon> <!-- أو استخدم <i class="fa fa-whatsapp"></i> إذا كنت تستخدم Font Awesome -->
    </button>
    <button 
      v-if="showScrollToTop" 
      @click="scrollToTop" 
      class="scroll-to-top show">
      <b-icon icon="arrow-up"></b-icon>
    </button>
  </div>
</template>

<script>
import navbar from "./components/navbar.vue"
import theFooter from "./components/footer.vue"

export default {
  metaInfo: {
    title: 'EverOn-admin',
    meta: [
      { property: 'og:description', content: 'Remote work force & Resources Management.' }
    ]
  },
  components: {
    navbar,
    theFooter
  },
  data() {
    return {
      showScrollToTop: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200;
    },
    openWhatsApp() {
      window.open('https://wa.me/201501806083', '_blank'); // لاحظ الصيغة الدولية: "201211192913"
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    showNavbar() {
      const hideSidebarKeywords = ['login', '*'];
      const currentPath = this.$route.path;
      const shouldHideSidebar = hideSidebarKeywords.some(keyword => currentPath.includes(keyword));
      return !shouldHideSidebar && this.$route.name !== 'NotFound';
      // const noSidebarPaths = ['/ar/login', '/en/login', '*', '/en/forget-password', '/ar/forget-password'];
      // return !noSidebarPaths.includes(this.$route.path) && this.$route.name !== 'NotFound';
    },

    showFooter() {
      const hideFooterKeywords = ['login', '*'];
      const currentPath = this.$route.path;
      const shouldHideFooter = hideFooterKeywords.some(keyword => currentPath.includes(keyword));
      return !shouldHideFooter && this.$route.name !== 'NotFound';
    },
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    mainContentId() {
      return this.$route.path.includes('login') ? null : 'main-content';
    },
  },
  // mounted() {
  //   // تعطيل زر الماوس الأيمن
  //   document.addEventListener('contextmenu', event => event.preventDefault());

  //   // تعطيل اختصارات لوحة المفاتيح مثل F12 وCtrl+Shift+I وCtrl+Shift+C وCtrl+U
  //   document.addEventListener('keydown', event => {
  //     if (
  //       event.key === 'F12' ||
  //       (event.ctrlKey && event.shiftKey && event.key === 'I') ||
  //       (event.ctrlKey && event.shiftKey && event.key === 'C') ||
  //       (event.ctrlKey && event.key === 'U')
  //     ) {
  //       event.preventDefault();
  //     }
  //   });
  //   document.addEventListener('copy', event => {
  //     event.preventDefault();
  //     alert('نسخ المحتوى غير مسموح.');
  //   });
  //   // التحقق من أدوات المطور
  //   const detectDevTools = () => {
  //     const threshold = 160; // العتبة لمعرفة ما إذا كانت أدوات المطور مفتوحة
  //     const widthThreshold = window.outerWidth - window.innerWidth > threshold;
  //     const heightThreshold = window.outerHeight - window.innerHeight > threshold;

  //     if (widthThreshold || heightThreshold) {
  //       alert('أدوات المطور مفتوحة. سيتم إغلاق هذه الصفحة.');
  //       window.location.href = 'about:blank'; // أو يمكنك إعادة توجيه المستخدم إلى صفحة أخرى
  //     }
  //   };

  //   setInterval(detectDevTools, 1000); // تحقق كل ثانية

  //   // طريقة أخرى باستخدام debug trick
  //   const element = new Image();
  //   Object.defineProperty(element, 'id', {
  //     get: function () {
  //       alert('أدوات المطور مفتوحة. سيتم إغلاق هذه الصفحة.');
  //       window.location.href = 'about:blank';
  //     }
  //   });
  //   console.log(element);
  // },

}

</script>

<style scoped>
:lang(ar) {
  direction: rtl;
}

/* توجيه النص إلى اليسار للإنجليزية */
:lang(en) {
  direction: ltr;
}

.navbar {
  z-index: 999;
  height: 70px;
}

.content {
  margin-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  /* height: 1000px; */
}

#main-content {
  /* max-height: 1200px; */
  min-height: 500px;
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  margin-top: 70px;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2274BD; /* لون الخلفية */
  color: white; /* لون النص */
  border: none;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 1000; /* لضمان ظهور الزر فوق جميع العناصر الأخرى */
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top b-icon {
  font-size: 20px; /* حجم الأيقونة */
}

/* whatts */

.whatsapp-icon {
  position: fixed;
  bottom: 150px;
  right: 20px;
  background-color: #25D366; /* لون الخلفية لزر WhatsApp */
  color: white; /* لون الأيقونة */
  border: none;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000; /* لضمان ظهور الزر فوق جميع العناصر الأخرى */
  transition: background-color 0.3s ease;
}

.whatsapp-icon:hover {
  background-color: #128C7E; /* لون الخلفية عند التمرير فوق الزر */
}

.whatsapp-icon b-icon {
  font-size: 24px;
}
</style>