<template>
    <div class="container custom-container mt-5">
        <section class="orders-section mt-5  py-5">
            <b-row class="align-items-center mb-5">
                <b-col cols="4"></b-col>
                <b-col cols="5" class="d-flex align-items-center">
                    <h2 class="text-center request-header"><span>Our</span> Orders</h2>
                </b-col>
                <b-col cols="3" class="text-right">
                    <b-button variant="warning" @click="goToOrdersPage()" class="request-button">{{$t('message.more')}}</b-button>
                </b-col>
                <b-col cols="1"></b-col>
            </b-row>
            <!-- <b-container>
            <b-row class="mb-4">
                <b-col cols="6">
                    <h2>Our Orders</h2>
                </b-col>
                <b-col cols="6" class="text-right">
                    <b-button variant="warning">More</b-button>
                </b-col>
            </b-row>-->
            <b-row>
                <b-col md="5" sm="12">
                    <img :src="require('@/assets/order-img.svg')" alt="Hero Image" class="img-fluid orders-image">
                </b-col>
                <b-col md="7" sm="12" class="orders-item">
                    <div class="orders-content">

                        <swiper-container class="mySwiper" pagination="true" direction="vertical" autoplay-delay="2500"
                            effect="cards" grab-cursor="true">
                            <swiper-slide v-for="item in swiperItems" :key="item.orderNumber">
                                <div class="slider-content">
                                    <div class="slider-header">
                                        <h1> {{$t('message.companyName')}}</h1>
                                        <h3> {{ user.name }} </h3>
                                    </div>
                                    <div class="slider-header">
                                        <h1> {{$t('message.orderNumber')}}</h1>
                                        <h3> {{ item.timeOrderId }} </h3>
                                    </div>
                                    <div class="slider-header">
                                        <h1> {{$t('message.services')}}</h1>
                                        <h3> {{ item.serviceName }} </h3>
                                    </div>
                                    <div class="slider-header">
                                        <h1> {{$t('message.history')}}</h1>
                                        <h3> {{ item.call_date }} </h3>
                                    </div>
                                    <div class="slider-header">
                                        <h1> {{$t('message.status')}}</h1>
                                        <h3> {{ item.order_status }} </h3>
                                    </div>

                                </div>
                            </swiper-slide>
                            <!-- <swiper-slide>Slide 2</swiper-slide>
                            <swiper-slide>Slide 3</swiper-slide>
                            <swiper-slide>Slide 4</swiper-slide>
                            <swiper-slide>Slide 5</swiper-slide>
                            <swiper-slide>Slide 6</swiper-slide>
                            <swiper-slide>Slide 7</swiper-slide>
                            <swiper-slide>Slide 8</swiper-slide>
                            <swiper-slide>Slide 9</swiper-slide> -->
                        </swiper-container>
                    </div>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>

import axiosInstance from '../axios-config';
import { mapGetters } from 'vuex'

export default {
    name: "OrdersSection",
    components: {

    },
    data() {
        return {
            swiperItems: [
                // {
                //     componyName: 'Total',
                //     orderNumber: '1254789',
                //     service: 'Air Conditioning',
                //     history: '2023/01/22  8:00pm',
                //     status: 'in processing',
                // },
                // {
                //     componyName: 'Total',
                //     orderNumber: '1254789',
                //     service: 'Air Conditioning',
                //     history: '2023/01/22  8:00pm',
                //     status: 'in processing',
                // },
                // {
                //     componyName: 'Total',
                //     orderNumber: '1254789',
                //     service: 'Air Conditioning',
                //     history: '2023/01/22  8:00pm',
                //     status: 'in processing',
                // },
                // {
                //     componyName: 'Total',
                //     orderNumber: '1254789',
                //     service: 'Air Conditioning',
                //     history: '2023/01/22  8:00pm',
                //     status: 'in processing',
                // },
                // {
                //     componyName: 'Total',
                //     orderNumber: '1254789',
                //     service: 'Air Conditioning',
                //     history: '2023/01/22  8:00pm',
                //     status: 'in processing',
                // },

                // يمكنك إضافة المزيد من الطلبات هنا  
            ],
        };
    },
    computed: {
        ...mapGetters(['getUser']),
        company_id() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        user() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        }
    },
    methods: {
        async fetchData() {
            try {
                const formData = new FormData();
                // formData.append('current_date', '2023-12-14');
                formData.append('admin_id', this.company_id.id);
                const response = await axiosInstance.post('admin/select_latest_order_by_admin_id.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.swiperItems = response.data.output;
                    console.log('swiperItems = ', this.swiperItems);

                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },
        goToOrdersPage() {
            this.$nextTick(() => {
                this.$router.push('/orders');
            });
        }
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.orders-section {
    padding-left: 30px;
    padding-right: 30px;
}

.custom-container {
    padding-left: 60px;
    padding-right: 60px;
}

/* .carousel {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.carousel-indicators {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px !important;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    height: 100%;
} */

/* .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0;
    background-color: #ccc;
    cursor: pointer;
} */

/* .carousel-indicators .active {
    background-color: #FFCA1F;
} */
/* .carousel-inner {
    position: relative;
    width: 65% !important;
} */
swiper-container {
    width: 300px;
    height: 425px;
}

swiper-slide {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}

swiper-slide:nth-child(1n) {
    /* border-radius: 10px;
  background-image: url('../assets/slider-bg.svg');
  background-position: right;
  background-repeat: no-repeat; */
    background-color: #e9f0ff;

}

swiper-slide:nth-child(2n) {
    background-color: #e9f0ff;
}

swiper-slide:nth-child(3n) {
    background-color: #e9f0ff;
}

swiper-slide:nth-child(4n) {
    background-color: #e9f0ff;
}

swiper-slide:nth-child(5n) {
    background-color: #e9f0ff;
}

.request-button {
    background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
    border-radius: 20px;
    color: #fff;
    width: 100px;
    font-size: 21px;
    font-family: 'Somar-bold';
    border-width: 0px;
}

.request-button:hover {
    outline: none;
    border-width: 0px;
    background-image: linear-gradient(90deg, #FFD44A 0%, #FFCA1F 100%);
    transition: all 0.3s ease-in-out;
    color: #fff;
}


.slider-header h1 {
    color: #2B5FA8;
    font-family: 'Somar-Bold';
    font-size: 25px;
}

.slider-header h3 {
    color: #45566D;
    font-family: 'Somar-Bold';
    font-size: 25px;
}

.slider-content {
    margin-left: 24px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.slider-header {
    margin-bottom: 10px;
}

.request-header {
    font-family: 'Somar-Bold';
    font-size: 30px;
    color: #2274BD;
    text-transform: uppercase;
}

.request-header span {
    color: #FFD448;
}

@media (max-width: 576px) {
    .custom-container {
        padding-left: 15px;
        padding-right: 15px;

    }
    .orders-item{
        overflow: hidden;
        margin-top: 50px
    }
    .request-button {
        width: 75px;
        font-size: 18px;
    }
}
</style>
