<template>
  <div class="container custom-container mt-5">
    <h2 class="text-center request-header"><span>Our</span> Requests</h2>
    <b-row class="align-items-center">
      <b-col cols="6" class="d-flex align-items-center">
        <h3 class="filter-text">{{ $t('message.filter') }}</h3>
        <b-form-input
          class="filter-input"
          id="filter-input"
          v-model="filter"
          :placeholder="$t('message.requestTitle')"
        ></b-form-input>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button variant="warning" @click="goToRequestPage()" class="request-button">{{ $t('message.more') }}</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="request in filteredRequests"
        :key="request.number"
        cols="12" md="6" class="mb-3 mt-5"
      >
        <b-card class="request-card">
          <b-card-text>
            <div class="request-info-row" :class="[{ 'request-info-row-home-rtl': isRTL, 'request-info-row-home-ltr': !isRTL }]">
              <span><strong>{{$t('message.requestNumber')}}:</strong> <div class="request-response"><span> {{ request.equpmentId }} </span></div></span>
              <span class="date-time"><b-icon class="clock-icon" icon="clock" aria-hidden="true"></b-icon>{{ request.created_date }} {{ request.time }}</span>
            </div>
            <div style="">
            <p class="request-info"><strong>{{$t('message.requestTitle')}}:</strong><div class="request-response"><span> {{ request.name }} </span></div></p>
          </div>
          <div style="line-height: 0px;">
            <p class="request-info"><strong>{{$t('message.equipmentType')}}:</strong> <div class="request-response"> <span> {{ request.equipment_type }}</span></div></p>
          </div>
          </b-card-text>
          <template #footer>
            <!-- <b-button variant="info">Show Details</b-button> -->
            <p>{{$t('message.showDetails')}}</p>
          </template>
        </b-card>
      </b-col>
      <template v-if="filteredRequests.length === 0">
        <div class="text-center p-4" style="width: 100%;">
            <!-- <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                    colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                </lord-icon> -->
            <img :src="require('../assets/not-found.svg')" alt="404 Photo" style="width:100%;">
            <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
        </div>
      </template>
    </b-row>
  </div>
</template>

<script>

import axiosInstance from '../axios-config';
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      filter: '',
      requests: [
        // {
        //   number: '125/7832',
        //   date: '2023/01/02',
        //   time: '12:00 pm',
        //   title: 'Add & Equipment',
        //   addedBy: 'Admin Name',
        // },
        // {
        //   number: '125/7833',
        //   date: '2023/01/02',
        //   time: '12:00 pm',
        //   title: 'Remove Equipment',
        //   addedBy: 'Admin Name',
        // },
        // يمكنك إضافة المزيد من الطلبات هنا
      ],
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    company_id() {
        return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
    },
    filteredRequests() {
      return this.requests.filter(request =>
        request.name.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    isRTL() {
        return this.$i18n.locale === 'ar';
      },
  },
  created(){
    this.fetchData();
  },
  methods: {
    
    async fetchData() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            console.log(formattedDate);
            try {
                const formData = new FormData();
                formData.append('current_date', formattedDate);
                // formData.append('offset', 0);
                formData.append('company_id', this.company_id.company_id);
                formData.append('days', 3);
                const response = await axiosInstance.post('company/select_pm_orders.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                  this.requests = response.data.output.slice(-2);
                  console.log('requests = ', this.requests);

                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });
            }
        },

      goToRequestPage(){
        this.$nextTick(() => {
            this.$router.push('/Requests');
        });
      }
    }
  
};
</script>

<style scoped>

</style>
