<template>
    <div v-if="isLoading" class="loading-container">
        <img :src="require('@/assets/10.png')" alt="Logo" class="loading-logo" />
    </div>
    <div v-else>
        <div class="slide">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header"> {{ $t('message.ourRequests') }}</h2></div>
                <div>
                    <h5 class="slide-h5">{{ $t('message.home') }}</h5>
                    <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                    <h5 class="slide-h5"> {{ $t('message.requests') }}</h5>
                </div>
            </div>
            </div>
        </div>
        <div class="container custom-container-orders mt-5">
            <b-row class="">
                <b-col cols="6" class="d-flex align-items-center">
                    <h3 class="filter-text">{{ $t('message.filter') }}</h3>
                    <b-form-input class="filter-input" id="filter-input" v-model="filter"
                    :placeholder="$t('message.adminName')"></b-form-input>
                </b-col>
                <b-col cols="6" class="text-right">
                    <!-- <b-button variant="warning" @click="newRequest()" class="new-order">Add new Requests</b-button> -->
                </b-col>
            </b-row>

            <b-row>
                <b-col v-for="request in filteredRequests" :key="request.equpmentId" cols="12" md="6" class="mb-3 mt-5">
                    <b-card class="request-card">
                        <b-card-text>
                            <div class="request-info-row" :class="[{ 'request-info-row-rtl': isRTL, 'request-info-row-ltr': !isRTL }]">
                                <span><strong>{{$t('message.requestNumber')}}:</strong>
                                    <div class="request-response"><span> {{ request.equpmentId }} </span></div>
                                </span>
                                <span class="date-time"><b-icon class="clock-icon" icon="clock"
                                        aria-hidden="true"></b-icon>{{ request.created_date }}</span>
                            </div>
                            <div style="">
                                <p class="request-info"><strong>{{$t('message.requestTitle')}}:</strong>
                                <div class="request-response"><span> {{ request.name }} </span></div>
                                </p>
                            </div>

                            <div style="">
                                <p class="request-info"><strong>{{$t('message.addedBy')}}:</strong>
                                <div class="request-response"> <span> {{ 'System' }}</span></div>
                                </p>
                            </div>
                            
                            
                        </b-card-text>
                        <template #footer>
                            <div @click="showDetails(request.equpmentId)" style="cursor: pointer; width: 100%;text-align: center">
                                <!-- <b-button variant="info">Show Details</b-button> -->
                                <p>{{ $t('message.showDetails') }}</p>
                            </div>
                        </template>
                    </b-card>
                </b-col>
                <template v-if="filteredRequests.length === 0">
                        <div class="text-center p-4" style="width: 100%;">
                            <!-- <lord-icon src="https://cdn.lordicon.com/wkppeqfv.json"
                                    colors="primary:#121331,secondary:#edd100" trigger="loop" style="width:100px;height:100px">
                                </lord-icon> -->
                            <img :src="require('../assets/not-found.svg')" alt="404 Photo" style="width:100%;">
                            <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ filter }}".</p>
                        </div>
                        </template>
            </b-row>
            <div class="mt-5 pagination">
                    <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../axios-config';
import { mapGetters } from 'vuex'

export default {
    name: "about",
    components: {
        //   slider,
    },
    data() {
        return {
            filter: '',
            requests: [],
            rows: 0,
            currentPage: 1,
            perPage: 10,
            isLoading: true,
        };

    },
    computed: {
        ...mapGetters(['getUser']),
        company_id() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        filteredRequests() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            const filtered = this.requests.filter(request =>
            request.name.toLowerCase().includes(this.filter.toLowerCase())
            );
            this.rows = filtered.length; // تحديث إجمالي الصفوف بعد التصفية
            return filtered.slice(start, end);
        },
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        this.fetchData();
    },
    methods: {

        async fetchData() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            console.log(formattedDate);
            try {
                const formData = new FormData();
                formData.append('current_date', formattedDate);
                // formData.append('offset', 0);
                formData.append('company_id', this.company_id.company_id);
                formData.append('days', 3);
                const response = await axiosInstance.post('company/select_pm_orders.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.requests = response.data.output;
                    console.log('requests = ', this.requests);
                    this.isLoading = false;

                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$izitoast.error({
                    title: 'Error',
                    message: 'An error occurred while trying to get data',
                    position: 'bottomLeft',
                    timeout: 5000,
                });
                this.isLoading = false;
            }
        },
        showDetails(itemId){
            // this.$router.push('show-details/' + itemId);
            // this.$router.push(`/requests/${itemId}/this.$router.push('/addNewOrder');`);
            this.$router.push(`/addNewOrder/${itemId}`);
            // /requests/:id/details
        },
        
    }
}

</script>

<style scoped>
.row {
    /* justify-content: center; */
}

.request-card {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.b-pagination-pills .page-item .page-link {
    line-height: 0.8;
}

.request-info {
    margin-bottom: 0px !important;
}

p,
.request-info-row {
    margin-bottom: 0px !important;
}

/* .request-info-row strong {
    margin-right: 38px !important;
} */

.card-footer p {
    margin-bottom: 0px !important;
    font-family: 'Somar-Bold';
    font-size: 21px;
}

.date-time {
    color: #777D85 !important;
}

.card-footer:hover {
    background-color: #3C89CE !important;
    transition: all 0.3s ease-in-out !important;
    cursor: pointer;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.card-footer:hover p {
    color: #fff !important;
    transition: all 0.3s ease-in-out !important;
}
.new-order{
    background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
    border-radius: 20px;
    color: #fff;
    width: 200px;
    font-size: 21px;
    font-family: 'Somar-bold';
    border-width: 0px;
}
.new-order:hover{
    outline: none;
    border-width: 0px;
    background-image: linear-gradient(90deg, #FFD44A 0%, #FFCA1F 100%);
    transition: all 0.3s ease-in-out;
    color: #fff;
}
.pagination{
    justify-content: center;
    display: flex;
    align-items: center;
}

</style>