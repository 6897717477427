import Vue from 'vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import enMessages from './locales/en.json'
import arMessages from './locales/ar.json'
// import 'bootstrap-icons/font/bootstrap-icons.css'
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta';

// Import iziToast
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast';

import App from './App.vue'
import { createRouter } from './router'

import '../src/styles/main.css'


const messages = {
  en: enMessages,
  ar: arMessages
}

const firebaseConfig = {
  apiKey: "AIzaSyDays6WJ7BJipozDdCUvXAzrhX167BQzLc",
  authDomain: "maintenance-de2d0.firebaseapp.com",
  databaseURL: "https://maintenance-de2d0-default-rtdb.firebaseio.com",
  projectId: "maintenance-de2d0",
  storageBucket: "maintenance-de2d0.appspot.com",
  messagingSenderId: "424970044498",
  appId: "1:424970044498:web:cf17313d97f6061ec40b8b",
  measurementId: "G-6MNFZE74HY"
};

firebase.initializeApp(firebaseConfig);

Vue.prototype.$izitoast = iziToast;


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(VueI18n)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});


Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'en', // اللغة الافتراضية
  fallbackLocale: 'ar', // اللغة البديلة
  messages: {
    en: enMessages,
    ar: arMessages
  }
})

// استدعاء الدالة createRouter وتمرير i18n كخاصية لـ Vue Router
const vueRouter = createRouter(i18n)

new Vue({
  router: vueRouter,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export { i18n };
